import './scripts/common';
import './scripts/common-nav';

let _config = window['_config'] || {};
import {translate} from '@elements/translations';
import {findIn, find} from '@elements/dom-utils';

let app = {
    debug: false,
};
window.app = app || {};

import {createApp} from 'vue';
import BookingQuickfinder from './scripts/components/VueBookingQuickfinder.vue';
const vueApp = createApp({
    components: {
        BookingQuickfinder
    }
})
vueApp.mount('#vue-booking-quickfinder');

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init({
    counter: false,
    enableDrag: false,
    backgroundColor: '#868686',
    hideScrollbar: true,
});


import * as datepicker from "@elements/datepicker";
datepicker.init();

import * as datepickerRange from '@elements/datepicker-range';
datepickerRange.init();


import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass:"icon icon-chevron-right",
    dots: true,
    arrowRenderFunction: arrowRenderFunction,
    dotsRenderFunction: dotsRenderFunction
}, {
    base: '.js-scroll-slider',
    area: '.js-scroll-slider__area',
    item: '.js-scroll-slider__item',
    dotsContainer: '.js-teaser-slider-area__dots-container'
});
import * as sliderDotsAnimations from './scripts/slider-dots-animation'
sliderDotsAnimations.init()

function arrowRenderFunction(container, direction, disabled, options) {
    let markupElement = document.createElement('div'),
    arrowMarkup = `<button type="button" class="${options.arrowClass} scroll-slider__arrow--cca ${direction === 0 ? options.arrowPrevClass : options.arrowNextClass}" ${disabled ? 'disabled' : ''}><span class="scroll-slider__arrow-icon icon icon-chevron ${direction === 0 ? 'icon-rotate-90' :  'icon-rotate-270' }" aria-label="${translate('scroll-slider-'+ direction + '')}" title="${translate('scroll-slider-'+ direction + '')}"></span></button>`;
    markupElement.innerHTML = arrowMarkup;
    let newArrow = markupElement.childNodes[0];
    let arrowContainer = findIn('.js-scroll-slider__arrow-wrapper', container);
    arrowContainer.appendChild(newArrow);
    return newArrow;
}

function dotsRenderFunction(id = null, dotContainer, options) {
    let dotNamesArray = JSON.parse(dotContainer.dataset.imgTextSliderDotNames);
    let markupElement = document.createElement('div'),
        dotMarkup = `<button type="button" class="${options.dotsClass} js-scroll-slider__dots-btn ${id === 0 ? 'is-active' : ''}" data-id="${id+1}" data-dot-name="${dotNamesArray[id]}">${dotNamesArray[id]}</button>`;
    markupElement.innerHTML = dotMarkup;
    let newDot = markupElement.childNodes[0];
    dotContainer.appendChild(newDot);
    return newDot;
}

import * as scrollSliderCounter from './scripts/scroll-slider-counter';
scrollSliderCounter.init();

import * as scrollSliderExtd from './scripts/scroll-slider-extended';
scrollSliderExtd.init();

import * as playVideo from './scripts/play-video';
playVideo.init();

// import * as externalVideo from './scripts/external-video';
// externalVideo.init();

import * as sidebarToggle from './scripts/sidebar-toggle';
sidebarToggle.init();

import * as roomPlanLightbox from './scripts/room-plan-lightbox';
roomPlanLightbox.init();

import * as roomPlanSlider from './scripts/room-plan-slider';
roomPlanSlider.init();

import * as roomDetailHeroVideo from './scripts/room-detail-hero-video';
roomDetailHeroVideo.init();

import * as roomDetailHeroImage from './scripts/room-detail-hero-image';
roomDetailHeroImage.init();

import * as cookieFirstOverlay from './scripts/cookie-first-overlay';
cookieFirstOverlay.init();

import * as parallax from './scripts/parallax';
parallax.initInScope();
